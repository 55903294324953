import React, { useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
const axios = require("axios");

const friendOptions = [
  {
    key: "1",
    text: "Single Invoice",
    value: "1",
    image: {
      avatar: true,
      src:
        "https://cdn.iconscout.com/icon/premium/png-256-thumb/invoice-159-734749.png",
    },
  },
  // {
  //   key: "2",
  //   text: "Paid Invoice's",
  //   value: "3",
  //   image: {
  //     avatar: true,
  //     src: "https://image.flaticon.com/icons/png/512/1611/1611179.png",
  //     // "https://cdn2.iconfinder.com/data/icons/finance-icons-1/256/Financial_Report-512.png",
  //   },
  // },
  {
    key: "2",
    text: "Invoice W/O SCID",
    value: "2",
    image: {
      avatar: true,
      src:
        "https://cdn2.iconfinder.com/data/icons/finance-icons-1/256/Financial_Report-512.png",
    },
  },

  {
    key: "3",
    text: "PI W/O SCID",
    value: "3",
    image: {
      avatar: true,
      src:
        "https://cdn2.iconfinder.com/data/icons/finance-icons-1/256/Financial_Report-512.png",
    },
  },
  {
    key: "4",
    text: "Invoice B2C",
    value: "4",
    image: {
      avatar: true,
      src:
        "https://cdn2.iconfinder.com/data/icons/finance-icons-1/256/Financial_Report-512.png",
    },
  }
];

const InvoiceTypeDropdown = (props) => {
  function send_to_parent(value) {
    props.passChildData(value);
  }

  return (
    <Dropdown
      placeholder="Select Invoice Type"
      fluid
      selection
      options={friendOptions}
      onChange={(e, { value }) => {
        send_to_parent(value);
        //  alert(value);
        //alert(e)
      }}
    />
  );
};

export default InvoiceTypeDropdown;
